
  export const style={
    inpuStyle:{
      colorInput:"#2A5991"
    },
    url:{
      urlClient:"https://voke.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/voke_primary_logo.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: null,
    },
    CurrencyName:{
      name:"Vokito/Vokitos"
    },
    teamName:{
      name:"Voke"
    },
    hasToken: false,
    hostHeader: "voke"
  }
